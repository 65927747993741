import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import InfoBox from "../../components/InfoBox";
import QuoteBox from "../../components/QuoteBox";
import CtaPrimary from "../../components/CtaPrimary";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";


const breadCrumbLevels = {
  Hjem: "/",
  "Analytics Consulting": "/da/analyse-konsulent",
  "GTM Tutorial": "/da/google-tag-manager-opsætning"
};

// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/google-tag-manager-setup"
);


const FAQs = `
  {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "Har jeg brug for Google Tag Manager?",
          "acceptedAnswer": {
          "@type": "Answer",
          "text": "Ja, fordi din hjemmeside sandsynligvis ønsker at køre Google Analytics og andre tredjeparts script-tags. At opsætte alt det er meget lettere og hurtigere med Google Tag Manager. Plus din side <a href='https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173'>indlæser også lidt hurtigere</a>."
          }
        },
        {
        "@type": "Question",
        "name": "Hvornår skal jeg bruge Google Tag Manager?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Allerede hvis du kun vil køre Google Analytics, bør du bruge Google Tag Manager. At opsætte <a href='https://bluerivermountains.com/da/event-sporing'>hændelsessporing</a> og andre konfigurationer er meget hurtigere og lettere med GTM. Derudover er der mange tutorials og guider online, der forklarer, hvordan man f.eks. <a href='http://bluerivermountains.com/da/google-analytics-opsætning'>opsætter Google Analytics</a>."
          }
        },
        {
          "@type": "Question",
          "name": "Hvordan bruger jeg Google Tag Manager med Google Analytics?",
          "acceptedAnswer": {
          "@type": "Answer",
          "text": "Sæt ikke Google Analytics-tagget i kildeteksten på din side. Tilføj kun Google Tag Manager-tagget og implementer og <a href='https://bluerivermountains.com/da/google-analytics-opsætning'>opsæt Google Analytics</a> gennem Google Tag Manager. Eventuelle tilpassede konfigurationer som <a href='https://bluerivermountains.com/da/event-sporing'>hændelsessporing</a> eller tilføjelse af andre script-tags, gør du i GTM."
          }
       },
       {
         "@type": "Question",
         "name": "Hvad er forskellen mellem Google Analytics og Google Tag Manager?",
         "acceptedAnswer": {
         "@type": "Answer",
         "text": "Google Analytics er biblioteket, der indsamler data om besøg på din hjemmeside. Google Tag Manager derimod er et bibliotek, der kører på din side for at implementere andre biblioteker eller værktøjer som Google Analytics. Fordi mange af disse værktøjer har ekstra JavaScript-snippets til at sende data til dem, opsætter du dem alle i GTM."
         }
      },
      {
        "@type": "Question",
        "name": "Hvor placerer jeg Google Tag Manager-koden?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Den første del af koden går så højt som muligt ind i <head> sektionen. Jeg anbefaler at implementere den inden for <head>, men efter eventuelle <style> eller <script> tags, der er vigtige for at gengive siden - fordi vi ikke ønsker at forsinke dem. Den anden del af GTM-kode snippet er for at muliggøre en grundlæggende funktionalitet på sider med JavaScript deaktiveret. Det går så højt som muligt ind i <body> elementet. Logikken bag placeringen af begge tags er at sikre den tidlige indlæsning af GTM. Det giver dig mulighed for at gribe ind og kontrollere dele af sideindlæsningsprocessen så tidligt som muligt."
        }
     },
     {
       "@type": "Question",
       "name": "Inkluderer Google Tag Manager Google Analytics?",
       "acceptedAnswer": {
       "@type": "Answer",
       "text": "Nej, men Google Tag Manager giver dig mulighed for at implementere Google Analytics på få sekunder med blot nogle få klik. Det eneste du behøver er dit Google Analytics tracking ID. Generelt set behøver du dog ikke bruge Google Analytics med Google Tag Manager. De er uafhængige af hinanden."
       }
    }
    ]
  }
  `;

const GTMsetup = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Installer Google Tag Manager Tutorial: Opsætningsguide ${currentYear}`}
        description="Lær hvordan du bruger GTM til at opsætte Google Analytics, hændelsessporing, remarketing tags og et data lag, plus bedste praksis for sporing af downloads og eksterne links."
        lang="da"
        canonical="/da/google-tag-manager-opsætning"
        image="google-tag-manager-tutorial-hero.png"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <ImgScreenshot
          src="gtm-setup/google-tag-manager-tutorial-hero.png"
          alt="brugergrænseflade af Google Tag Manager"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Google Tag Manager Tutorial</H>

        <p>Som en <Link to="/da/google-tag-manager-konsulent">Google Tag Manager konsulent</Link> har jeg opsat GTM på <b>100+ kundewebsteder</b>. Denne Google Tag Manager tutorial er hvor jeg lærer dig processen, jeg har forfinet gennem årene, trin for trin, med eksempler og videoer til at lære af.</p>
        <p>Længere nede kan du <Link to="/da/google-tag-manager-opsætning">downloade en GTM opsætningskonfigurationsfil</Link> med alle de følgende bedste praksisser til at importere i din container.</p>
        <p>Hvis du ikke kan vente, spring direkte ind i <Link to="/da/google-tag-manager-opsætning">installationen</Link> tutorialen eller lær <Link to="/da/google-tag-manager-opsætning">hvordan man opsætter Google Tag Manager</Link>. Men hvis du er en <b>begynder</b>, er det vigtigt først at forstå <em>hvordan</em> man bruger et <Link to="/da/tag-styring">tag management system</Link> sammen med andre værktøjer.</p>
        <p>Så læs videre nedenfor først.</p>
        <H as="h2">Hvordan bruger man Google Tag Manager?</H>
        <p>Jeg antager, at du allerede ved <Link to="/da/hvad-er-google-tag-manager">hvad Google Tag Manager er</Link>. Så lad os tale om, hvordan GTM fungerer, og hvordan man bruger det.</p>
        <p>Ideelt set ønsker du kun at have <b>ét</b> 3.-parts tag i kildekoden på din hjemmeside eller webapp.</p>
        <QuoteBox
          quote={`Det eneste 3.-parts tag på din hjemmeside bør være Google Tag Manager kode snippet.`}
        />
        <p>Alle andre tags implementeres derefter gennem tag manageren selv. Andre marketing- og sporings-tags er f.eks. Google Analytics (GA), Facebook, Twitter, LinkedIn, AdWords, DoubleClick og gud ved hvad.</p>
        <p>Den primære grund er <Link to="/da/fordele-ved-google-tag-manager">fordelene ved Google Tag Manager</Link>:</p>
        <ul>
          <li><b>nemmere & hurtigere</b> implementering af marketing-tags</li>
          <li>skalerbarhed på hver side og på tværs af flere domæner</li>
          <li><b>indbyggede triggere</b> for formularindsendelser, rulningssporing & videosporing</li>
          <li>administrer brugere med flere GTM-konti</li>
          <li>en smule <a rel="noopener" target="_blank" href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">hurtigere indlæsningstid</a></li>
        </ul><br />
        <p>På grund af disse fordele bruger allerede <a target="_blank" href="https://w3techs.com/technologies/overview/tag_manager">30% af alle websteder på internettet en tag manager</a>. Og blandt dem har Google Tag Manager en markedsandel på <a target="_blank" rel="noopener" href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet">94%</a>.</p>
        <p>Så medmindre du har en solid grund til ikke at tilføje et tag til GTM, som en generel tommelfingerregel, <b>tilføj alle tags til GTM-containeren</b>.</p>

        <QuoteBox
          quote={`Brug GTM som et forbindelseslag mellem din hjemmeside og 3.-parts tags.`}
        />
        <p>Brug GTM som et <b>mellemlag</b> mellem din hjemmeside og 3.-parts tjenester. Uden det er din side og 3.-parts tags ikke i direkte forbindelse. Disse tjenester er for det meste JavaScript biblioteker til marketing eller sporingsværktøjer, der implementeres med et tag. Men andre tags gælder også.</p>
        <p>Den eneste undtagelse til reglen gælder, når du udfører konverteringsoptimering med split-test værktøjer.</p>
        <p>Fordi under konverteringsrateoptimering vil A/B-tests indlæse forskellige varianter af en side. Så besøgende kan se, hvordan indholdet genindlæses i et splitsekund.</p>
        <p>For at undgå CSS-flimmer og sikre, at varianttests indlæses hurtigt, kan et split-test tag også gå direkte ind i kildekoden.</p>
        <p>Nu hvor vi har dette på plads, lad os se på implementeringen.</p>
        <H as="h2">Installer Google Tag Manager på din hjemmeside</H>
        <p>Lad os starte Google Tag Manager tutorialen ved at vise dig, hvor du kan få Google Tag Manager kode snippet, og derefter hvor du skal installere det på hjemmesiden. Du kan logge ind bare ved at bruge din sædvanlige Google-konto.</p>
        <ol>
          <li><H as="h3">Opret en Google Tag Manager konto</H>
            For at installere GTM skal du først gå til den <a rel="noopener" target="_blank"
              href="https://tagmanager.google.com/">officielle hjemmeside</a> og oprette en ny Google Tag Manager konto.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-create-account.png"
              alt="Oprettelse af Google Tag Manager konto"
              caption="Først skal du oprette en Google Tag Manager konto og vælge et container navn, som din hjemmesides navn, og derefter få kode snippet."
              className="article-img"
            />
          </li>
          <li><H as="h3">Opret en web-ejendom</H>
            Vælg <b>Web</b> ejendommen for at få en kode til en hjemmeside eller webapp.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-analytics-setup-via-google-tag-manager-container-creation.png"
              alt="GTM container navn og valg af web-ejendom"
              className="article-img"
              caption="Der er flere typer containere tilgængelige i en GTM-konto. For hjemmesider, vælg web. Bemærk, at der er andre tag manager containertyper for AMP sider, iOS og Android også."
            />
          </li>
          <li><H as="h3">Implementer Google Tag Manager koden</H><ul>
            Derefter vil du blive vist Google Tag Manager koden til at implementere på din hjemmeside.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-code-snippet.png"
              alt="Google Tag Manager kode snippet"
              caption="Dette er Google Tag Manager container tag. Det har to dele. Instruktionerne om, hvordan man implementerer script tags, er skrevet over hver del."
              className="article-img"
            />
            <li>Tag <b>første del</b> af <Link to="/da/wiki-analytics/container-maerke">container tagget</Link> og placer det så højt som muligt i <b>head</b> tagget på hver side af din hjemmeside. Dette sikrer, at du kan affyre tags tidligt under sideindlæsninger.</li>
            <li><b>Anden del</b> er en iframe til at køre i browsere, der har JavaScript deaktiveret. Installer tagget så højt som muligt i <b>body</b> tagget på hver side af din hjemmeside.<br />
              <ImgScreenshot
                src="data-layer/positionierung-data-layer-gtm.png"
                alt="dataLayer er placeret før TMS tagget i kildekoden"
                caption={`Det første tag i <head> er et data layer. Vær ikke bekymret, hvis du endnu ikke ved, hvad det er (første pil). Dernæst er første del af GTM tagget (anden pil). Endelig er den anden del af GTM tagget implementeret højt oppe i <body> elementet. Eller JavaScript kode kan implementeres ind imellem, men et data layer er altid implementeret før GTM tagget, og <noscript> GTM tagget er altid sidste.`}
                className="article-img"
              />
            </li>
          </ul>
          </li>

        </ol>
        <p>Dette er den almindelige metode til at implementere GTM.</p>
        <p>Bruger du et populært content management system? Hvis ja, kan du også bruge et <b>plugin</b>, der tager sig af installationen af Google Tag Manager.</p>
        <p>Det sagt:</p>
        <InfoBox
          type="info"
          headline="Hvis dit CMS også tilbyder dig et plugin til at installere andre tags"
          h="false"
          dots="true"
          tweetcopy="Hvis dit CMS også tilbyder dig et plugin til at installere andre tags, så brug ikke endnu et plugin til at installere Google Analytics, Facebook eller Google Ads. Brug i stedet GTM til at installere disse tags."
        >
          <p>Brug ikke endnu et plugin til at installere Google Analytics, Facebook eller Google Ads.</p>
          <p>Brug i stedet <b>GTM til at installere disse tags</b>.</p><br />
          <ol>
            <li>Det vil resultere i en hurtigere sideindlæsning</li>
            <li>Det giver dig flere muligheder for at konfigurere tagget</li>
          </ol>
        </InfoBox>
        <p>GTM-brugergrænsefladen modtager også regelmæssigt opdateringer med nye funktioner, så du er næsten altid bedre stillet ved at implementere andre marketing-tags direkte med det end med en anden integration.</p><p>Plus, forbedringerne i indlæsningstid er gode for din <Link to="/da/wiki-analytics/afvisningsprocent">bounce rate</Link> og hjælper SEO.</p>
        <H as="h3">Brug et plugin til at implementere GTM</H>
        <p>Nedenfor er en liste over de mest almindelige content management systemer og deres plugins til at installere Google Tag Manager.</p>
        <H as="h4">WordPress</H>
        <p>Der er to WordPress plugins til at implementere GTM, som jeg vil anbefale. <b>Først</b> er der den klassiske mulighed kaldet <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">Google Tag Manager
          for WordPress</a>.<br />
          Den <b>anden</b> mulighed er <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/google-site-kit/">Site
            Kit by Google</a>. Det tillader primært, at du tilføjer et dashboard til din WordPress-backend, der viser information fra din Google Analytics-konto og Google Search Console data - hvilket er ret smart. Og det tillader dig også at installere GTM.</p>
        <H as="h4">Shopify</H>
        <p>For Shopify er der et <b>gratis</b> plugin til GTM-installation, kreativt kaldet <em><a rel="noopener" target="_blank"
          href="https://apps.shopify.com/trafficguard?surface_detail=google+tag+manager&amp;surface_inter_position=1&amp;surface_intra_position=6&amp;surface_type=search">Google
          Tag Manager Installer</a></em>.</p>
        <H as="h4">Squarespace</H>
        <p>For Squarespace er der ingen GTM-udvidelse eller plugin. Men du kan tilføje GTM-tagget manuelt ved at gå til <b>sidebar</b> &gt; <b>settings</b> &gt; <b>advanced</b> &gt; <b>code injection</b>.</p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-installation-in-squarespace.png"
          alt="Squarespace navigationsmenu for kodeindsprøjtning"
          caption={`I Squarespace kan du implementere GTM under Indstillinger > Avanceret > Kodeindsprøjtning`}
          className="article-img"
        />

        <p>Derefter indsætter du GTM-tagget i formularfelterne som dette:</p>


        <ImgScreenshot
          src="gtm-setup/gtm-code-injection-in-squarespace.png"
          alt="Indsæt GTM kode snippets i Squarespace"
          caption={`Placer den første del af GTM-koden i header-feltet. Den anden del går i footer-feltet.`}
          className="article-img"
        />

        <H as="h4">Wix</H>
        <p>Gå til hovedmenuen for dit Wix-websted i venstre sidebar. Derfra gå til <b>Marketing &amp; SEO</b> og klik derefter på <b>Marketing Integrations</b> længere nede i sidebar.<br />
          Derefter finder du flere Wix-integrationer for Google Analytics, Facebook-pixel og også en Wix-udvidelse til at implementere Google Tag Manager.</p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-setup-in-wix.png"
          alt="Wix Marketing Integration for Google Tag Manager"
          caption={`I Wix bruger du marketing-integrationen til Google Tag Manager.`}
          className="article-img"
        />
        <p>Klik på tilslut og få Google Tag Manager installeret.</p>










        <H as="h2">Hvordan tjekker man, om GTM virker?</H>
        <InfoBox
          type="info"
          headline="Når du først logger ind på GTM"
          h="false"
          dots="true"
          tweetcopy="Når du først logger ind på GTM...Gå til submit-knappen og publicer en tom container. Ellers, når du tester, om GTM virker, vil scriptet returnere en 400 response fejl, og du vil bruge timer på at fejlsøge hvorfor. 😭"
        >
          <p>Gå til submit-knappen og publicer en <b>tom container</b>.</p>
          <p>Ellers, når du tester, om GTM virker, vil scriptet returnere en <b>400 response fejl</b>, og du vil bruge timer på at fejlsøge hvorfor. 😭 <br /><br />Det er en klassiker 😉</p>
        </InfoBox>
        <p>Efter du har implementeret GTM-scriptet og <b>publiceret en container</b> version (vigtigt), kan du teste, om Google Tag Manager virker ved at udføre nogen af disse checks:</p>
        <ol>
          <li><H as="h3">Preview og debug tilstand</H>Log ind på din GTM-konto og klik på <b>preview</b>. Åbn derefter en ny fane i browseren og besøg din hjemmeside. GTM debugger-vinduet skulle poppe op i bunden af vinduet, hvis GTM fungerer korrekt.<br />
            <ImgScreenshot
              src="event-tracking/google-tag-manager-event-trigger-preview.png"
              alt="Aktiver GTM debugger tilstand"
              caption={`Aktiver GTM debugger tilstand for at tjekke, om GTM fungerer korrekt.`}
              className="article-img"
            />

          </li>
          <li><H as="h3">Chrome Developer Tools</H><b>Åbn Chrome Developer Tools</b> med et højreklik på en hvilken som helst side på din hjemmeside og vælg <em>inspicér</em> (Alternativt F12 på Windows og Shift+CTRL+J på Mac).<br />
            Gå derefter til fanen <b>netværk</b> og <b>genindlæs websiden samtidig</b> (F5 på Windows og CMD+Shift+R
            på Mac). Netværksfanen vil fyldes med alle netværksanmodninger, der er nødvendige for at indlæse siden.<br />
            I filterfeltet øverst til venstre, skriv <em>gtm.js</em> for at finde anmodningen om din JavaScript-kode og bekræft, at den har
            en <b>statuskode på 200</b>.<br /><br />
            Lad mig vise dig:<br /><br />
            <video

              loading="lazy"
              title={`Tjek om Google Tag Manager virker`}
              autoplay
              muted
              playsinline
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/check-if-gtm-is-working.mp4" type="video/mp4" />
            </video>
            <br />
            <b>Hvis du ikke har en statuskode på 200, har du måske glemt at indsende og publicere en container først i GTM?</b></li>
          <li><H as="h3">Google Tag Assistant</H>Installer <a rel="noopener" target="_blank" href="https://chrome.google.com/webstore/detail/tag-assistant-by-google/kejbdjndbnbjgmefkgdddjlbokphdefk">Google Tag Assistant</a> Chrome-udvidelsen og start den på din side. Den skal kalde et GTM container ID.<br />

            <ImgContainerFixed width="452px">
              <ImgScreenshot
                src="gtm-setup/gtm-validation-with-tag-inspector.png"
                alt="Google Tag inspector validerer, at GTM indlæses korrekt"
                caption={`Du kan også bruge Chrome Extension Google Tag Assistant til at sikre, at Google Tag Manager fungerer korrekt.`}
                className="article-img"
              />
            </ImgContainerFixed>
          </li>
        </ol>
        <H as="h2">Hvordan opsætter man Google Tag Manager?</H>
        <p>Ved opsætning af Google Tag Manager kan du lave mange avancerede konfigurationer. Så <b><em>hvordan</em></b> du opsætter GTM afhænger
          af, hvilke andre værktøjer du planlægger at bruge i dit <Link to="/da/tag-styring">tag management system</Link>.</p>
        <p>Derfor har jeg samlet alle relevante tutorials, der dækker, hvad du muligvis vil opsætte i din GTM-konto - med
          eksempler. Følg blot denne Google Tag Manager guide og skab dermed et solidt tag management fundament for din side.</p>
        <p>Kun tutorialen om implementering af et data lag kræver kodningsfærdigheder eller potentielt webudviklere.</p>
        <p><b>Bemærk</b>: I denne Google Tag Manager tutorial vil vi bruge GTM ved <b>manuelt</b> at opsætte nye tags og triggere for hver hændelse. Tilgangen er ikke super skalerbar, men den er hurtig nok og nem, samtidig med at den opfylder de fleste sporingsambitioner og stadig kan anvendes til andre avancerede opsætninger.</p>
        <p>Større websteder og e-handelsbutikker kræver en <b>skalerbar tag management løsning</b>. Derfor implementeres et <Link to="/da/data-lag">data lag</Link> som den centrale del til at spore hændelser. Med en sådan opsætning kan du bruge hændelseshåndterere i stedet for at opsætte tags, triggere og variable for hver hændelse.</p>
        <ol>
          <li>
            <H as="h3">Opsæt Google Analytics sporing</H>
            <p>Dette er det første skridt for alle. Lær i denne guide, hvordan du implementerer solid Google Analytics sporing med mål, tragte og dine egne besøg
              ekskluderet fra trafikken. Plus flere bedste praksisser.</p>

            <Link to="/da/google-analytics-opsætning"><CtaPrimary color="purple" arrow="true">Opsæt Google Analytics</CtaPrimary></Link>

          </li>
          <li>
            <H as="h3">Opsæt hændelsessporing</H>
            <p>Når den grundlæggende sporingsimplementering kører som den skal, vil du også lære at spore bruger
              engagement. Hvor ofte sender en besøgende for eksempel formularindsendelser og klikker på en send-knap eller et andet HTML-element? Min <Link to="/da/event-sporing">hændelsessporing</Link> guide forklarer præcis det for et <b>knapklik</b> og
              du kan bruge den samme metode til enhver anden kliksporing.</p>
            <Link to="/da/event-sporing"><CtaPrimary color="purple" arrow="true">Opsæt hændelsessporing</CtaPrimary></Link>
          </li>
          <li>
            <H as="h3">Tilføj remarketing tags</H>
            <p>Den mest almindelige brugssag for GTM <em>efter</em> installation af GA er at tilføje remarketing tags til en hjemmeside. Når alt kommer til alt, udgør de størstedelen af 3.-parts marketing tags og sporingskoder, der roder i vores kodebase.<br />
              Derfor implementerer vi dem gennem vores GTM-konto for at holde kodebasen fri for marketing- og analysetags, samtidig med at vi drager fordel af <Link to="/da/fordele-ved-google-tag-manager">fordelene ved Google Tag Manager</Link>.</p>
            <p>Lad os lære at tilføje de mest almindelige remarketing tags i den digitale marketingverden, <b>Facebook-pixel</b> og <b>Google Ads
              remarketing tag</b>.</p>
            <H as="h4">Tilføj Facebook-pixel</H>
            <ul>
              <li>
                Først skal du bruge dit <b>Facebook pixel ID</b>. Besøg Facebooks <a rel="noopener" target="_blank"
                  href="https://www.facebook.com/events_manager2/list/pixel/">Event Manager</a> og klik på <b>det grønne plus-symbol</b> for at oprette Facebook pixel. Herefter vil dit pixel ID være oplyst på
                skærmen.
                <ImgScreenshot
                  src="gtm-setup/get-fb-pixel.png"
                  alt="Event Manager i Facebook viser pixel ID'et"
                  caption={`Find dit Facebook pixel ID i Event Manager.`}
                  className="article-img"
                />
              </li>
              <li>
                Opret derefter en <b>ny tag</b> via Google Tag Manager, kald den for eksempel <em>Facebook - Sidevisning</em> og besøg galleriet for<b>tag skabeloner</b>.
              </li>
              <li>
                Søg efter <em>Facebook</em> og vælg Facebook Pixel.<br />

                <ImgScreenshot
                  src="gtm-setup/fb-pixel-template-gallery-gtm.png"
                  alt="GTM's skabelongalleri viser Facebook pixel tag"
                  caption={`Implementer Facebook pixel fra GTM's tag skabeloner.`}
                  className="article-img"
                />
              </li>
              <li>
                Tilføj dit <b>Facebook Pixel ID</b> og klik på <b>gem</b>.
              </li>
              <li>
                Indstil tagget til at affyre på <b>alle sider</b>.
                <br /><br />
                <video

                  loading="lazy"
                  autoplay
                  muted
                  playsinline
                  loop
                  controls
                  title={`Installer Facebook pixel i Google Tag Manager`}
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/add-facebook-pixel-with-gtm.mp4" type="video/mp4" />
                </video>
              </li>
              <li>
                Klik derefter på <b>indsend</b> i øverste højre hjørne for at publicere tagget.
              </li>
            </ul>
            <H as="h4">Tilføj Google Ads remarketing</H>
            <ul>
              <li><b>Først</b> skal du hente dit <b>Google Ads konverterings-ID</b> for dit publikum fra <b>Shared Library &gt; Audiences</b>. Brugergrænsefladen er for nylig ændret, men kig efter <b>tag detaljer</b> eller <b>opsæt tag</b> for at finde nedenstående information.
                <br />
                <ImgContainerFixed width="432px">
                  <ImgScreenshot
                    src="gtm-setup/google-ads-conversion-id.png"
                    alt="Kode snippets for Google konverterings-ID og konverteringslabel"
                    caption={`Tag dit konverterings-ID og konverteringslabel fra tag detaljerne i dit publikum.`}
                    className="article-img"
                  />
                </ImgContainerFixed>
              </li>
              <li>Gå derefter til <b>tags</b> sektionen i GTM og klik på <b>ny</b> for at tilføje vores nye marketing tag.</li>
              <li>Giv det et navn som <em>Google Ads - Sidevisning</em>.</li>
              <li>Vælg som type variabel <b>Google Ads Remarketing</b>.</li>
              <li>Indstil dit <b>konverterings-ID</b> og eventuelt <b>konverteringslabel</b>. Lad derefter tagget affyre på <b>alle sider</b> og klik på <b>gem</b>.<br /><br />Lad mig vise dig i denne video:
                <video

                  loading="lazy"
                  autoplay
                  muted
                  playsinline
                  loop
                  controls
                  title={`Installer Google Ads sidevisning med GTM`}
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/google-ads-page-view.mp4" type="video/mp4" />
                </video>
              </li>
              <li>Klik på <b>indsend</b> i øverste højre hjørne for at publicere marketing tagget.</li>
            </ul>

          </li>
          <li>
            <H as="h3">Implementér et data lag</H>
            <p>Du vil gerne implementere et data lag, hvis du regelmæssigt opsætter tags, og det tager for lang tid og er simpelthen for arbejdskrævende.</p>
            <p>En anden fordel er, at du kan bruge informationen fra din database til at affyre triggere eller sende det som hændelsesdata. Andre eksterne datakilder kan også integreres. Websites, der har brug for ecommerce tracking falder typisk i denne kategori.</p>
            <p>Min artikel om data laget forklarer implementeringen, data lag variabler og hvordan man konfigurerer tilpasset sporing på en skalerbar måde, hvilket er en typisk brugssag for <b>store ecommerce butikker</b>, der har brug for avanceret ecommerce tracking.
            </p>

            <Link to="/da/data-lag"><CtaPrimary color="purple" arrow="true">Implementér data lag</CtaPrimary></Link>
          </li>
        </ol>
        <H as="h2">Bedste praksis for enhver GTM opsætning</H>
        <p>Hver gang jeg opsætter Google Tag Manager, følger opsætningen med nogle få konfigurationer, som jeg tilføjer hver gang. Disse
          bedste praksis er anvendelige og nyttige for de fleste virksomheder og bør ikke mangle i nogen GTM tutorial. Se listen nedenfor og vælg dem, der er nyttige for dig.</p>
        <p>Længere nede kan du <Link to="/da/google-tag-manager-opsætning">downloade en GTM opsætningskonfiguration</Link> med alle disse bedste praksis til at importere i din egen container.</p>
        <H as="h3">Spor eksterne links</H>
        <p>At spore klik på eksterne links betyder at spore ethvert klik på eksterne links, der fører fra din hjemmeside til andre websites.
          Sporing af eksterne links er en bedste praksis, der lader dig vide, hvilke websites du sender besøg til, og hjælper dig med at verificere dine besøgendes interesse.<br />For at implementere sporing af eksterne links er der <b>tre trin</b>:</p>
        <ol>
          <li>
            <H as="h4">Opret en brugerdefineret hændelsesvariabel i GTM</H>
            <ul>
              <li>Besøg <b>brugerdefinerede variabler</b> fra GTM oversigten og klik på <b>ny &gt; auto-hændelsesvariabel</b>.</li>
              <li>Den skal evaluere link URL'en på et klikket element og returnere <code>true</code> hvis <code>href</code> attributten holder et eksternt link - eller <code>false</code> hvis linket er internt.</li>
              <li>Giv den nye variabel et navn som <em>Linkisoutbound</em> og vælg <em>variabeltype</em> til at være <b>Element URL</b> og <em>Komponenttype</em> <b>er ekstern</b>. Klik derefter på <b>gem</b>.<br />

                <ImgScreenshot
                  src="gtm-setup/auto-event-variable-for-outbound-links.png"
                  alt="Indstillinger for auto-hændelsesvariabel for eksterne links"
                  caption="Auto-hændelsesvariablen i Google Tag Manager vil holde en værdi af TRUE, når adressen på det klikkede link er eksternt og peger på en ekstern side."
                  className="article-img"
                />

              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Opret en ny trigger for eksterne links</H>
            <ul>
              <li>I side navigationen under triggere, klik på ny og opret en ny trigger i GTM.</li>
              <li>Vælg <em>triggertype</em> til at være <b>bare links</b> og navngiv den f.eks. <em>Hændelse - Eksternt link</em>.</li>
              <li>Indstil triggeren til <em>at affyre på nogle linkklik</em> og vælg din nye auto-hændelsesvariabel <b>Linkisoutbound</b> til at være lig med <code>true</code>:<br />

                <ImgScreenshot
                  src="gtm-setup/outbound-links-trigger-config.png"
                  alt="Trigger konfiguration for sporing af eksterne links"
                  caption={`Trigger indstillinger for sporing af eksterne links.`}
                  className="article-img"
                />

              </li>

            </ul>
          </li>
          <li>
            <H as="h4">Opret en tag for GA hændelsen</H>
            <ul>
              <li>Besøg <b>variabler</b> og klik <b>konfigurer</b> for <em>de indbyggede variabler</em>. Aktiver derefter <b>click URL</b> variablen. Luk igen og gå til tag sektionen.</li>
              <li>Opret en ny <b>Google Analytics: Universal Analytics</b> tag navngivet <em>Hændelse - Eksternt link</em> med <em>sporings type</em> indstillet til <b>hændelse</b>.</li>
              <li>For <b>kategori</b> tilføj blot <code>Eksternt link klik</code>, <b>handling</b> er <code>klik</code> og <b>label</b> skal referere til vores nyligt aktiverede GTM variabel. For at gøre det bruger vi dobbelt krøllede
                parenteser <code>{`{{ Click URL }}`}</code>.</li>
              <li>Hændelsens <b>værdi</b> kan sættes til <code>0</code>, medmindre du kan tildele en nominel værdi til et
                eksternt link (f.eks. for affiliate links).</li>
              <li>Indstillingen <b>non-interaction hit</b> skal være indstillet til <code>false</code>, fordi et eksternt klik
                faktisk er en brugerinteraktion.</li>
              <li><b>Google Analytics indstillingsvariabel</b> skal være den samme brugerdefinerede variabel som i din tag for sidevisninger.<br />

                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-external-link-clicks.png"
                  alt="Indstillinger for Google Analytics hændelse tag til sporing af eksterne links"
                  caption={`Indstillinger for hændelse tag til sporing af eksterne links.`}
                  className="article-img"
                />

              </li>

              <li>Som det sidste trin, rul ned til <em>udløsning</em> sektionen og <b>vælg den nyligt oprettede trigger</b> <em>Hændelse - Eksternt Link</em>. Tryk derefter på <b>gem</b> og <b>indsend</b> dine container ændringer fra
                hovedoversigten.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Spor klik på emailadresser</H>
        <p>Clicks på emails er en nyttig metrik, der har tendens til at korrelere med telefonopkald eller besøg i en fysisk butik.<br />For at opsætte Google Analytics sporing af email klik følg trinene i nedenstående tutorial:</p>
        <ol>
          <li>
            <H as="h4">Tilføj en ny trigger til klik på emailadresser</H>
            <ul>
              <li>Aktivér <b>click URL</b> den indbyggede variabel. Du kan springe dette trin over, hvis du allerede har gjort det i
                den tidligere opsætning. Ellers: Gå til <b>variabler</b> sektionen og <b>konfigurer</b> de <em>indbyggede
                  variabler</em> for at tilføje <b>click URL</b> variablen. Luk derefter panelet og gå til
                triggers sektionen.</li>
              <li>Under <b>triggers</b>, klik <em>ny</em> og opret en ny trigger navngivet f.eks. <em>Hændelse - Mail
                klik</em>, indstil typen til <b>klik - bare links</b> og lad den kun udløse på <em>nogle link
                  klik</em>.</li>
              <li>I betingelsen for disse link klik, indstil dropdowns til at være <b>Click URL</b>, derefter <b>indeholder</b> og til sidst tilføj strengen <code>mailto:</code><br /></li>

              <ImgScreenshot
                src="gtm-setup/email-click-trigger-settings-in-gtm.png"
                alt="Trigger konfiguration for sporing af klik på emailadresser"
                caption={`Trigger konfiguration for sporing af klik på emailadresser.`}
                className="article-img"
              />

            </ul>
          </li>
          <li>
            <H as="h4">Opsæt GA hændelse tag</H>
            <ul>
              <li>Opret en ny tag, vælg tag type <b>Google Analytics: Universal Analytics</b> og giv den navnet <em>Hændelse - Email
                link</em>. Vælg derefter <b>hændelse</b> som <em>sporings type</em>.</li>
              <li><b>Kategorien</b> kan sættes til en statisk streng <code>Email link klik</code>, <b>handlingen</b> er <code>klik</code> og <b>etiketten</b> skal referere til <code>{`{{ Click URL }}`}</code>.</li>
              <li>Hændelsens <b>værdi</b> kan igen være <code>0</code>, medmindre du kan tildele en meningsfuld værdi. Hvis
                du vidste for eksempel, at hver 10. email kontakt fører til et salg på 100$, så kunne du tildele 10$ som
                den gennemsnitlige hændelsesværdi.</li>
              <li><b>Non-interaction hit</b> skal vise <code>false</code>, da interaktionen er frivillig og
                derfor aktiv.</li>
              <li><b>Google Analytics indstillinger</b> variablen skal være den samme som for din sidevisning tag eller eksternt
                link tag.<br />


                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-emails.png"
                  alt="Konfiguration af GA hændelse tag til email klik"
                  caption={`Konfigurationsindstillinger for GA hændelse tag til emails.`}
                  className="article-img"
                />

              </li>
              <li>Det sidste trin er at gå ned til <em>udløsning</em> og <b>vælg den trigger, vi lige har oprettet</b> <em>Hændelse - Mail
                klik</em>. Derefter <b>gem</b> og gå til oversigten for at <b>indsend</b> dine ændringer.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Spor klik på telefonnumre</H>
        <p>Sporing af klik på telefonnumre er primært nyttigt på mobile enheder. Et klik på et telefonnummer link initiere direkte
          et telefonopkald. På desktops initierer museklik normalt ikke noget. Men ligesom ved sporing af klik på
          emails, er det en god metrik til at bekræfte kontaktfrekvenser generelt, fordi det korrelerer med andre metoder til
          at kontakte en virksomhed.</p>
        <p>Lær at konfigurere GTM til at spore klik på telefonnumre ved at følge nedenstående trin.</p>
        <ol>
          <li>
            <H as="h4">Opret en trigger for klik på telefonnumre</H>
            <ul>
              <li>Som med de andre guides ovenfor, skal vi have <b>click URL</b> variablen aktiveret fra <em>de indbyggede variabler</em>.</li>
              <li>Opret derefter en ny trigger <em>Hændelse - Telefon klik</em> af typen <b>klik - bare links</b> som kun
                udløser på <em>nogle link klik</em>.</li>
              <li>Udfyld trigger betingelsen som følger: <b>Click URL</b> - <b>indeholder</b> og derefter strengen <code>tel:</code>. Tryk derefter gem.<br />

                <ImgScreenshot
                  src="gtm-setup/phone-number-trigger-in-gtm.png"
                  alt="GTM indstillinger for en telefonnummer trigger"
                  caption={`Indstillinger for en trigger, der udløses ved klik på telefonnumre.`}
                  className="article-img"
                />


              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Opret en telefonnummer hændelse tag</H>
            <ul>
              <li>Tilføj igen en <b>Universal Analytics tag</b> af typen <b>hændelse</b> og kald den <em>Hændelse - Telefon klik</em></li>
              <li><b>Kategorien</b> er den statiske streng <code>Telefonnummer klik</code>, <b>handlingen</b> er <code>klik</code> og som <b>etiket</b> bruger vi den indbyggede variabel igen med dobbelt krøllede
                parenteser, <code>{`{{ Click URL }}`}</code>.</li>
              <li>Hvis du ikke kan tildele en gennemsnitlig værdi til et lead opkald, hold den på <code>0</code>. Ellers tilføj et tal for
                den gennemsnitlige værdi.</li>
              <li>Hændelse <b>non-interaction hit</b> skal sættes til <code>false</code>, fordi dette er en aktiv, frivillig
                interaktion. Vælg <b>indstillingsvariabelen</b> fra dine brugerdefinerede variabler, som du også bruger til sidevisninger.



                <ImgScreenshot
                  src="gtm-setup/ga-event-phone-number.png"
                  alt="Konfiguration af GA telefonnummer klik hændelse"
                  caption={`Konfiguration af GA telefonnummer klik hændelse.`}
                  className="article-img"
                />
              </li>
              <li>Forbind nu din nyoprettede trigger med denne GA hændelse tag ved at gå ned til udløsningsområdet og
                vælge den nye trigger <em>Hændelse - Telefon klik</em>. Det sidste trin er at <b>gemme</b> og klikke på indsend.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Spor downloads</H>
        <p>At spore, hvor ofte besøgende downloader dine materialer, er en god indikator for engagement. Sådanne downloads kan være f.eks.
          eBøger, Excel-ark, billeder, videoer eller musik.</p><p>Sporing af downloads fungerer godt til at skelne mellem besøgende grupper, der ikke var interesserede i sidens indhold, og besøgende der faktisk var interesserede og downloadede det, du tilbød.</p>
        <p>Følg denne tutorial for at lære at opsætte download sporing:</p>
        <ol>
          <li>
            <H as="h4">Konfigurer en GTM trigger til download klik</H>
            <ul>
              <li>Opret en ny trigger kaldet <em>Hændelse - Downloads</em>, som <em>triggertype</em> vælg <b>klik -
                bare links</b>, som kun affyrer på <b>nogle link klik</b>.</li>
              <li>For betingelsen sæt den indbyggede variabel <b>Click URL</b> til <b>slutter med</b> strengen <code>.pdf</code> for at spore for eksempel PDF downloads. Ændr udvidelsesstrengen til den filtype, du tilbyder til download.
              </li>
              <li>Hvis du vil spore <b>flere filtyper</b> til download kan vi bruge et regulært udtryk. Ændr betingelsen til <b>Click URL</b> og derefter <b>matcher RegEx (ignorér store/små bogstaver)</b> og tilføj dette RegEx udtryk <code>.pdf|.mp4|.mp3|.xlsx|.xls|.epub|.jpeg$</code>. Du er velkommen til at tilføje andre filtyper, der er relevante for din hjemmeside og fjerne andre, der ikke er. Afslut ved at klikke på <b>gem</b>.<br />


                <ImgScreenshot
                  src="gtm-setup/gtm-trigger-download-events.png"
                  alt="Trigger konfiguration for download sporing med indbyggede variabler"
                  caption={`Trigger konfiguration for download sporing med indbyggede variabler.`}
                  className="article-img"
                />


              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Spor downloads med en Google Analytics hændelse</H>
            <ul>
              <li>Tilføj en ny tag af typen Universal Analytics, vælg <b>hændelse</b> som sporings-type og navngiv den for eksempel <em>Hændelse - Downloads</em>.
              </li>
              <li><b>Kategorien</b> for hændelsen er strengen <code>Download</code>, <b>handlingen</b> er strengen <code>klik</code> og <b>etiketten</b> er <code>{`{{ Click URL }}`}</code>.</li>
              <li>Hændelse <b>non-interaction hit</b> er <code>false</code>, på grund af interaktionen er en aktiv engagement.</li>
              <li>Og fra de brugerdefinerede variabler, brug den samme <b>indstillingsvariabel</b> som i andre sporings tags.<br />

                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-downloads.png"
                  alt="Hændelse tag for download sporing"
                  caption={`Indstillinger af hændelse tag for download sporing.`}
                  className="article-img"
                />

              </li>

              <li>Tilføj nu en trigger til denne tag ved at rulle ned og vælge <em>Hændelse - Downloads</em>.</li>
              <li>Næste trin, klik <b>gem</b>, gå til oversigten og <b>indsend</b> ændringerne.</li>
            </ul>
          </li>
        </ol>

        <H as="h2">Guide til test af tags og triggere</H>
        <p>En Google Tag Manager tutorial ville ikke være komplet uden en del om debugging. For at teste nogen af de tidligere hændelseskonfigurationer og være sikker på, at de virker, gør noget af følgende:</p>
        <ol>
          <li><H as="h3">GTM Forhåndsvisningstilstand</H>Først, lad os starte forhåndsvisning og debugging tilstand. I Google Tag Manager oversigten, klik på <b>forhåndsvisning</b> knappen i øverste højre hjørne. Åbn derefter en ny
            fane <b>i samme browser</b> og du vil bemærke, at et GTM debugger vindue dukker op i bunden.<br /><br />
            Mens du er i forhåndsvisningstilstand, udfør tags og triggere selv. Klik f.eks. på et eksternt link eller et telefonnummer og se om
            triggeren og din tag vises i GTM debugger vinduet sammen med de hændelsesdetaljer, du tilføjede.<br />

            <ImgScreenshot
              src="event-tracking/google-tag-manager-debugging-mode.png"
              alt="google tag manager debugger for hændelser"
              className="article-img"
              caption={`Google Tag Manager debugger åbnes automatisk, når forhåndsvisningstilstand er aktiveret og viser data om tags, triggere og variabler.`}
            />


          </li>
          <li><H as="h3">Google Analytics realtidsrapport for hændelser</H>En anden metode er at logge ind på Google Analytics og derefter besøge <b>realtidsrapporten for hændelser</b>. Hændelserne bør vises et par sekunder efter, at triggeren er udløst.

            <ImgScreenshot
              src="event-tracking/google-analytics-event-tracking-realtime-report.png"
              alt="google analytics realtidsrapport for hændelser"
              className="article-img"
              caption={`Du kan verificere GTM konfigurationer med Google Analytics realtidsrapport.`}
            />


          </li>
          <li><H as="h3">Chrome udvidelse til debugging</H>Installer en Chrome-udvidelse til debugging af tag og trigger. Jeg anbefaler <a rel="noopener" target="_blank"
            href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh">Trackie</a> eller <a
              rel="noopener" target="_blank"
              href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl">Omnibug</a>, men
            der er også andre løsninger derude. Når du har tilføjet udvidelsen til Chrome, kan du åbne <b>Chrome Developer
              Tools</b> og der vil være en ny fane tilgængelig. Den viser dig alle oplysninger om de udløste tag management
            regler. Hvis du derefter udløser dine triggere i debug-tilstand, vil udvidelsen vise trigger- og hændelsesdata.
            <br />
            <ImgScreenshot
              src="gtm-setup/test-gtm-trigger-and-tag-with-trackie.png"
              alt="Trackie Chrome udvidelse viser hændelsesdata."
              className="article-img"
              caption={`Trackie Chrome udvidelse viser hændelsesdata i Chrome Developer Tools.`}
            />

            <ImgScreenshot
              src="gtm-setup/test-gtm-trigger-and-tag-with-omnibug.png"
              alt="Omnibug Chrome udvidelse viser hændelsesdata til debugging"
              className="article-img"
              caption={`Omnibug Chrome udvidelse viser hændelsesdata til debugging i Chrome Dev Tools.`}
            />
          </li>
        </ol>



        <H as="h2">Download GTM konfigurations container fil</H>
        <p>Da de ovenstående konfigurationer er universelt nyttige for de fleste Google Tag Manager implementeringer, har jeg lavet den ovenstående
          GTM opsætning som en fil til import i andre Google Tag Manager containere.<br />
          Det er en <code>.json</code> fil med de indstillinger og navngivningskonventioner, vi gennemgik. Du kan bare importere containerkoden uden at skulle opsætte noget manuelt.</p>
        <p>Enten <b>brug det med en helt ny container</b> for at spare tid på at opsætte tags selv, eller du kan <b>importere
          dem til din eksisterende container</b> og opdatere Google Analytics indstillingsvariablen inklusive tracking ID til dit eget ID.</p>
        <p>Du kan downloade og installere disse konfigurationer (hver med tags, triggere og variabler) til at opsætte Google Tag Manager:</p>
        <ul>
          <li>Spor eksterne links</li>
          <li>Spor email klik</li>
          <li>Spor telefonnummer klik</li>
          <li>Spor downloads</li>
        </ul>
        <p className="baseline">Importer blot containerindstillingerne og implementer dem. Til demonstrationsformål har jeg tilføjet en Google Analytics indstillingsvariabel med et Google Analytics sporings-ID på <em>UA-12345678-9</em>.</p>
        <p><b>Opdater venligst GA sporingskoden til din egen</b> eller
          alternativt, <b>ændr GA indstillingsvariablen i tag-konfigurationen til din egen</b>.</p>
        <p>Download GTM opsætningskonfigurationen og se nedenfor, hvordan du importerer den.</p>
        <a href="/downloads/best-practice-gtm-configs.json" download="best-practice-gtm-configs.json" ><CtaPrimary color="purple">Download GTM opsætning</CtaPrimary></a>
        <H as="h3">Importer indstillinger i din GTM container</H>
        <p>For at få mest muligt ud af denne GTM tutorial, følg nedenstående trin og importér indstillingerne til din GTM container:</p>
        <ul>
          <li>gå til <b>admin</b> &gt; <b>importer container</b>.</li>
          <li>vælg den JSON-fil, du lige har downloadet.</li>
          <li>vælg et <b>nyt arbejdsområde</b> navngivet f.eks. <em>Import Bluerivermountains</em>.</li>
          <li>som <b>importmulighed</b> vælg at <b>flette</b> og <b>omdøbe</b> eventuelle modstridende tags, triggere og variabler.</li>
          <li>klik på <b>bekræft</b> og tryk på <b>indsend</b> og <b>publicer</b> knappen for at implementere tags.<br /><br />Lad mig vise dig i denne video:

            <video

              loading="lazy"
              title={`Tutorial video til at importere en Google Tag Manager Container`}
              autoplay
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/import-gtm-container.mp4" type="video/mp4" />
            </video>

          </li>

          <li>Endelig, ændr GA sporings-ID i Google Analytics indstillingsvariablen til dit eget sporings-ID eller opdater indstillingsvariablen i
            tags til din egen indstillingsvariabel.</li>
        </ul>
        <H as="h2">Bemærk om personlige data</H>
        <p>Vi skal være opmærksomme på de oplysninger, vi sporer. Data er ikke bare data, fordi lande har regler om databeskyttelse, som påvirker typen af information, vi må indsamle under sporing.</p>

        <p>Ligeledes er der også vilkår fra Googles side, der forbyder sporing af personlige oplysninger og sende dataen til deres servere.</p>

        <p>I den forbindelse:</p>
        <p>Generelt er <b>emails eller telefonnumre personligt identificerbare oplysninger (PII)</b> og vi må ikke sende det til vores Google Analytics konto, fordi det er <a rel="noopener" target="_blank" href="https://support.google.com/analytics/answer/2795983">imod deres servicevilkår</a>.</p>
        <p>Men telefonnumrene på vores hjemmeside eller vores egne virksomheds emailadresser er næppe private, fordi det ikke er brugernes
          data men vores egne og offentligt tilgængelige på hjemmesiden.<br />
          Ikke desto mindre, hvis Google Analytics <em>nogensinde</em> tjekkede deres database og fandt de data, kunne de ikke vide,
          at det faktisk ikke er PII data.<br />
          Derfor anbefaler jeg ikke at tage nogen risiko og <b>obfuskere alle emailadresser og telefonnumre, der sendes til
            Google Analytics kontoen</b>.</p>
        <p>Simo Ahava gjorde et stort stykke arbejde og skrev et <a rel="noopener" target="_blank"
          href="https://www.simoahava.com/gtm-tips/remove-pii-google-analytics-hits/">custom task script for at fjerne PII
          fra Google Analytics hits</a> og jeg anbefaler, at du implementerer dette sammen med de ovenstående konfigurationer.<br />
          Det er ikke et must, men ved at implementere det undgår du enhver potentiel forvirring om, hvorvidt du har PII data eller ej.</p>
        <H as="h2">FAQ</H>
        <H as="h3">Har jeg brug for Google Tag Manager?</H>
        <p>Ja, fordi din hjemmeside sandsynligvis ønsker at køre Google Analytics og andre tredjeparts script tags. Opsætning af alt det
          er meget nemmere og hurtigere med Google Tag Manager. Plus din side <a rel="noopener" target="_blank"
            href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">indlæser
            også lidt hurtigere</a>.</p>
        <H as="h3">Hvornår skal jeg bruge Google Tag Manager?</H>
        <p>Allerede hvis du kun ønsker at køre Google Analytics, bør du bruge Google Tag Manager. Opsætning af <Link to="/da/event-sporing">hændelsessporing</Link>, cross-domain tracking eller formularsporing er almindelige næste trin, men meget <b>hurtigere</b> og <b>nemmere</b> med GTM end uden. Der er indbyggede triggere til rullesporing og videosporing også, plus mange tutorials online, der forklarer, hvordan man <Link to="/da/google-analytics-opsætning">opsætter Google Analytics</Link> med det.</p>
        <H as="h3">Hvordan bruger jeg Google Tag Manager med Google Analytics?</H>
        <p>Log ind på <a href="https://analytics.google.com" rel="noopener" target="_blank">analytics.google.com</a> med din Google konto og få din Google Analytics sporingskode inklusive sporings-ID. Nu, tilføj ikke Google Analytics tagget i kildekoden på din side. Det eneste hard-kodede tag skal være Google Tag Manager tagget. Så gå til <a target="_blank" rel="noopener" href="https://tagmanager.google.com/">tagmanager.google.com</a> for at få GTM kode snippet og implementer i stedet denne på hver side af din hjemmeside. Til sidst, implementér GA-koden gennem et indbygget tag, tilføj dit sporings-ID og fortsæt med at <Link to="/da/google-analytics-opsætning">opsætte Google Analytics</Link> <b>gennem Google Tag Manager</b>.
          For at tilføje marketing tags eller konfigurere brugerdefinerede tags, bruger du altid GTM fremadrettet.</p>
        <H as="h3">Hvad er forskellen mellem Google Analytics og Google Tag Manager?</H>
        <p>Google Analytics er biblioteket til at indsamle data om besøg og engagement på din hjemmeside. Google Tag Manager på den anden side er et bibliotek, der kører på din side for at implementere andre biblioteker eller <em>sporingsværktøjer</em> som Google Analytics. Fordi mange marketing- og analysetools har ekstra JavaScript snippets, bruger du GTM brugergrænsefladen til at opsætte dem alle.</p>
        <H as="h3">Hvor skal jeg placere Google Tag Manager koden?</H>
        <p>Den <b>første</b> del af koden går <em>så højt som muligt</em> ind i <code>&lt;head&gt;</code> sektionen. Jeg
          anbefaler at implementere den inden for <code>&lt;head&gt;</code> men efter eventuelle <code>&lt;style&gt;</code> eller <code>&lt;script&gt;</code> tags, der er vitale for at gengive siden - fordi vi ikke ønsker at forsinke dem.<br />
          Den <b>anden</b> del er for at aktivere en grundlæggende funktionalitet i browsere med JavaScript slået fra. Det går <em>så højt som muligt</em> ind i <code>&lt;body&gt;</code> elementet.<br />
          Logikken bag placeringen af begge tags er at sikre den tidlige indlæsning af GTM. Det gør det muligt at affyre brugerdefinerede tags så tidligt som muligt under sideindlæsning.</p>

        <br />
        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-code-snippet.png"
          alt="Google Tag Manager kode"
          className="article-img"
          caption={`GTM tagget har to dele. Den første placeres højt oppe i head tagget og den anden lige efter åbningen af body tagget (se nedenfor).`}
        />

        <br />

        <ImgScreenshot
          src="data-layer/positionierung-data-layer-gtm.png"
          alt="placering af datalayer før tag management tag i kildekoden"
          className="article-img"
          caption={`Den overordnede rækkefølge bør altid være 1. data layer objekt 2. Google Tag Manager, begge i head og 3. den anden GTM kode højt oppe i body tagget`}
        />

        <H as="h3">Indeholder Google Tag Manager Google Analytics?</H>
        <p>Nej, men Google Tag Manager gør det muligt at implementere Google Analytics på få sekunder med blot et par klik. Det eneste
          du har brug for er dit <Link to="/da/google-analytics-opsætning">Google Analytics sporings-ID</Link>. Generelt set behøver du dog ikke bruge Google Analytics med Google Tag Manager.
          De er <b>uafhængige</b> af hinanden.</p>
        <H as="h3">Hvordan får jeg adgang til Google Tag Manager?</H>
        <p>Besøg <a target="_blank" href="https://tagmanager.google.com/" rel="noopener">tagmanager.google.com</a> og log ind med din Google konto for at få adgang til Google Tag Manager. For at begynde at bruge GTM, opret en ny konto og vælg web-ejendom som målplatform. Tag derefter snippet og installer den på hver side af din hjemmeside.</p>
        <Helmet>
          <script type="application/ld+json">{FAQs}</script>
        </Helmet>
      </MainContent>
    </React.Fragment>

  </Layout >
);

export default GTMsetup;